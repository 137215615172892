
            @import 'src/styles/mixins.scss';
        
.hero-section {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/assets/images/hero.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    .button {
        @include button;
        background-color: #dd1f26;
    }
}