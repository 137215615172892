
            @import 'src/styles/mixins.scss';
        
.fallout-rate-section {
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
        @include button;
        background-color: #dd1f26;
    }

    background-image: url("/assets/images/low-fallout.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    .item {
        position: relative;
        &:not(:last-child) {
            &::before {
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 20px 20px 0 20px;
                border-color: #dd1f26 transparent transparent transparent;
                bottom: -20px;
                left: 50%;
                transform: translateX(-50%);
                content: "";
            }
        }
    }
}