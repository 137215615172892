
            @import 'src/styles/mixins.scss';
        
.item {
    &:not(:last-child) {
        position: relative;
        &::after {
            content: "";
            height: 48px;
            width: 100%;
            background-repeat: no-repeat;
            position: absolute;
            bottom: -40px;
            background-size: contain;
            background-image: url("/assets/images/separator-horizontal.svg");
            @include lg {
                width: 29px;
                background-image: url("/assets/images/separator.png");
                height: 100%;
                right: -29px;
                top: 0;
                bottom: unset;
            }
        }
    }
}