
            @import 'src/styles/mixins.scss';
        
.hot-torts-section {
    a {
        color: #dd1f26;
    }
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        top: 0;
        background-color: #f6f6f6;
        z-index: -1;
    }
}
.modal-content {
    ul {
        list-style: disc;
        padding-left: 1.5rem;
        font-weight: 300;
        ul: {
            list-style: circle;
        }
        @apply mb-2;
    }
    p {
        font-weight: 300;
        @apply mb-2;
    }
    h2 {
        @apply text-xl mb-2;
    }
}
.button {
    @include button;
    background-color: #dd1f26;
    color: white !important;
}

